import * as S from './MediaHubSearchFrom.styled'
import Button from '@global/Button'
import Field from '@global/FormInput/Field'
import IconSearch from '@global/icon/IconSearch'
import { Formik } from 'formik'
import { Router } from '@routes'
import { Container } from '@styles/global.styled'

const LIST_TAB = {
  all: {
    value: '',
    desktop: 'All',
    mobile: 'All',
  },
  news: {
    value: 'news',
    desktop: 'News',
    mobile: 'News',
  },
  blog: {
    value: 'blog',
    desktop: 'Insights',
    mobile: 'Insights',
  },
  resources: {
    value: 'resources',
    desktop: 'Guides & Resources',
    mobile: 'Guides',
  },
}

const MediaHubSearchFrom = ({
  onChangeTab,
  fetchData,
  setCurrentTab,
  currentTab,
  onSubmit,
  formRef,
  hideSearch = false,
}) => {
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    onSubmit && onSubmit(values)
    setSubmitting(false)
  }

  const handleTabChange = (tab) => {
    const currentPath = Router.pathname // Get the current path
    const officeMediaHubPattern = /^offices\/([^/]+)\//
    const { slug } = Router.query
    const match = slug.match(officeMediaHubPattern)
    setCurrentTab(tab)
    if (match && match[1]) {
      const officeName = match[1]
      Router.pushRoute(`/offices/${officeName}/media-hub/${tab}/`)
      fetchData && fetchData()
    } else {
      Router.pushRoute(`/media-hub/${tab}/`)
      fetchData && fetchData()
    }
  }
  return (
    <S.MediaHubSearchWrap>
      <Container className={'MediaHub-container'}>
        <S.MediaHubSearchFrom hideSearch={hideSearch}>
          <S.FilterOptions>
            {Object.keys(LIST_TAB).map((key) => (
              <S.FilterOptionItem
                active={currentTab === LIST_TAB[key].value}
                key={key}
                onClick={() => {
                  handleTabChange(LIST_TAB[key].value)
                }}
              >
                <span>{LIST_TAB[key].desktop}</span>
                <span>{LIST_TAB[key].mobile}</span>
              </S.FilterOptionItem>
            ))}
          </S.FilterOptions>

          {!hideSearch && (
            <Formik
              innerRef={formRef}
              initialValues={{ search: '' }}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isSubmitting, getFieldProps }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <S.FormGroup>
                    <S.SearchInput>
                      <IconSearch />
                      <Field
                        {...getFieldProps('search')}
                        className='search-input'
                        type='text'
                        placeholder='Start typing your search here'
                      />
                    </S.SearchInput>
                    <Button
                      color='secondary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      Search
                    </Button>
                  </S.FormGroup>
                </form>
              )}
            </Formik>
          )}
        </S.MediaHubSearchFrom>
      </Container>
    </S.MediaHubSearchWrap>
  )
}

export default MediaHubSearchFrom
