import React, { useRef, useState } from 'react'
import HeroSection from 'components/global/Blocks/Hero'
import WagtailBlocksSection from '@components/global/Blocks/WagtailBlocksSection'
import { getChildPages } from '@lib/api-services/cmsService'
import { apiFieldsQuery, itemShowPerPage } from '@lib/variables'
import MediaHubSearchFrom from 'components/MediaHub/MediaHubSearchForm'
import ResourceList from 'components/MediaHub/ResourceList'
import { Container, Section } from '@styles/global.styled'
import NoResults from '@components/MediaHub/NoResults'

const ResourcesIndexPage = (props) => {
  const { pageData, queryParams, meta } = props
  const [currentTab, setCurrentTab] = useState(
    pageData?.meta?.slug || 'resources'
  )
  const [items, setItems] = useState(props.items || [])
  const [page, setPage] = useState(queryParams.page || 1)
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)

  const getResources = async (value) => {
    setLoading(true)
    try {
      const resp = await getChildPages(
        queryParams.type,
        queryParams.parentId, //child_of
        queryParams.limit,
        page,
        {
          ...queryParams.params,
          search: value,
          order: value ? 'title' : '-created',
        },
        queryParams.fields
      )

      if (resp.items) {
        return resp
      }
    } catch (error) {
      // Handle the error here
      console.error('An error occurred while fetching the data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (values) => {
    const ressults = await getResources(values?.search)
    const itemsData =
      ressults &&
      ressults.items.map((item) => {
        return {
          ...item,
          id: item.id,
          title: item.title,
          description: item.description,
          type: item.resource_type,
          slug: item.meta.slug,
          imgUrl: item.image_thumbnail ? item.image_thumbnail.url : null,
          link: {
            label: '',
            url: item.resource_path,
          },
        }
      })

    setItems(itemsData)
  }

  const handleClearSearch = async () => {
    formRef.current && formRef.current.resetForm()
    await handleSubmit({ search: '' })
  }

  return (
    <>
      <HeroSection
        title={pageData.title}
        description={pageData.hero_text}
        bgImageUrl={pageData.hero_image.url}
        bgPosition={pageData.hero_image_anchor}
        titlePosition='bottom'
      />

      <Section>
        <MediaHubSearchFrom
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          onSubmit={handleSubmit}
          formRef={formRef}
        />
      </Section>

      <Section>
        <Container>
          {items && items.length > 0 ? (
            <ResourceList items={items} />
          ) : (
            <NoResults onClearSearch={handleClearSearch} />
          )}
        </Container>
      </Section>
      <WagtailBlocksSection blocks={pageData.content_blocks} />
    </>
  )
}

ResourcesIndexPage.getInitialProps = async (ctx, pageData) => {
  const queryParams = {
    type: 'media_hub.Resource',
    parentId: pageData.id,
    limit: itemShowPerPage.ResourcesIndexPage,
    page: 1,
    params: { order: '-created' },
    fields: apiFieldsQuery.resourcesPage,
  }

  let childrenPages = await getChildPages(
    queryParams.type,
    queryParams.parentId,
    queryParams.limit,
    queryParams.page,
    queryParams.params,
    queryParams.fields
  )

  return {
    queryParams,
    items:
      childrenPages.items &&
      childrenPages.items.map((item) => {
        return {
          ...item,
          id: item.id,
          title: item.title,
          description: item.description,
          type: item.resource_type,
          slug: item.meta.slug,
          imgUrl: item.image_thumbnail ? item.image_thumbnail.url : null,
          link: {
            label: '',
            url: item.resource_path,
          },
        }
      }),
    meta: childrenPages.meta || {},
  }
}
export default ResourcesIndexPage
