import React, { useEffect, useRef, useState } from 'react'
import HeroSection from '@components/global/Blocks/Hero'
import WagtailBlocksSection from '@components/global/Blocks/WagtailBlocksSection'
import { getChildPages } from '@lib/api-services/cmsService'
import {
  apiFieldsQuery,
  itemShowPerPage,
  domain,
  formSlugs,
} from '@lib/variables'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'
import MediaHubSearchFrom from 'components/MediaHub/MediaHubSearchForm'
import SignUpBanner from 'components/global/SignUpBanner'
import { Container, Section } from '@styles/global.styled'
import { getFormsFields } from '@lib/api-services/formsService'
import NewsListWithCards from 'components/MediaHub/NewsListWithCards'
import NoResults from '@components/MediaHub/NoResults'

const BlogIndexPage = (props) => {
  const { pageData, office, queryParams, signUpForm } = props
  const [currentTab, setCurrentTab] = useState(pageData?.meta?.slug || 'news')
  const [items, setItems] = useState(props.items || [])
  const [page, setPage] = useState(queryParams.page || 1)
  const [totalRecords, setTotalRecords] = useState(
    (props.meta && props.meta.total_count) || 1
  )
  const formRef = useRef(null)
  const [valueSearch, setValueSearch] = useState('')

  const getNews = async (values, isLoadMore = true, isSub) => {
    try {
      let updatedPage = page // Initialize the updated page with the current page value
      if (isLoadMore) {
        updatedPage += 1 // If it's a "Load more" action, increment the page value
      } else {
        updatedPage = 1 // If it's a "Search" action, set the page value to 1
      }

      const resp = await getChildPages(
        queryParams.type,
        queryParams.parentId, //child_of
        queryParams.limit,
        updatedPage, // Use the updated page value
        {
          ...queryParams.params,
          search: valueSearch,
          order: valueSearch ? 'title' : '-date',
          office_id:
            queryParams.params.office_id || (office ? office.id : null),
          index_type: pageData.index_type,
        },
        queryParams.fields
      )

      if (resp.items) {
        setTotalRecords((resp.meta && resp.meta.total_count) || 1)
        return resp
      }
    } catch (error) {
      // Handle the error here
      console.error('An error occurred while fetching the data:', error)
    }
  }

  const onSuccess = (data, newPage) => {
    const itemsData = data.map(({ id, title, header_image_results, meta }) => ({
      id,
      title,
      imgUrl: header_image_results && header_image_results.url,
      link: {
        label: 'read article',
        url: meta.path,
      },
    }))

    const newItems = items.concat(itemsData)
    setItems(newItems)
    setPage(newPage)
  }

  const handleSubmit = async (values) => {
    const searchValue = values?.search || ''
    setValueSearch(searchValue)
  }

  const handleClearSearch = async () => {
    formRef.current && formRef.current.resetForm()
    setValueSearch('')
  }
  const fetchData = async () => {
    const results = await getNews(valueSearch, false)
    const itemsData = results?.items?.map((item) => ({
      ...item,
      id: item.id,
      title: item.title,
      imgUrl: item.header_image_results?.url,
      link: {
        label: 'read article',
        url: item.meta?.path,
      },
      date: item.date ?? item.first_published_at,
    }))

    setTotalRecords(results?.meta?.total_count ?? 1)
    setItems(itemsData)
    setPage(1)
  }
  useEffect(() => {
    fetchData()
  }, [props, valueSearch])

  return (
    <>
      <HeroSection
        title={pageData.title}
        description={pageData.hero_text}
        bgImageUrl={pageData.hero_image.url}
        bgPosition={pageData.hero_image_anchor}
        titlePosition='bottom'
      />
      <Section>
        <MediaHubSearchFrom
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          fetchData={fetchData}
          onSubmit={handleSubmit}
          formRef={formRef}
        />
      </Section>

      <Section>
        <Container>
          {items && items.length > 0 ? (
            <NewsListWithCards items={items} />
          ) : (
            <NoResults onClearSearch={handleClearSearch} />
          )}
        </Container>
      </Section>

      {items && items.length > 0 && (
        <LoadmoreInfo
          total={totalRecords}
          numberShowing={page * itemShowPerPage.BlogIndexPage}
          apiCall={getNews}
          page={page}
          onSuccess={onSuccess}
        />
      )}

      <Section style={{ marginBottom: '80px' }}>
        <Container>
          <SignUpBanner />
        </Container>
      </Section>
      <WagtailBlocksSection blocks={pageData.content_blocks} />
    </>
  )
}

BlogIndexPage.getInitialProps = async (ctx, pageData) => {
  let childrenPages = null
  const queryParams = {
    type: 'blog.BlogPost',
    parentId: null,
    limit: itemShowPerPage.BlogIndexPage,
    page: 1,
    params: {
      order: '-date',
      index_type: pageData.index_type,
    },
    fields: apiFieldsQuery.BlogPost,
  }

  if (pageData.meta.office_ancestor && pageData.meta.office_ancestor.id) {
    queryParams.parentId = null
    queryParams.params.office_id = pageData.meta.office_ancestor.id
  } else {
    queryParams.parentId = pageData.id
  }

  childrenPages = await getChildPages(
    queryParams.type,
    queryParams.parentId,
    queryParams.limit,
    queryParams.page,
    queryParams.params,
    queryParams.fields
  )

  let signUpForm = await getFormsFields(
    formSlugs.emailSignup,
    'u-5/12@tablet u-3/12@desktop u-4/12@wide',
    'c-sign-up-banner__submit u-4/12@desktop u-3/12@wide'
  )

  return {
    queryParams,
    office: pageData.meta.office_ancestor,
    items:
      childrenPages.items &&
      childrenPages.items.map((post) => ({
        ...post,
        id: post.id,
        title: post.title,
        imgUrl: post.header_image_results && post.header_image_results.url,
        link: {
          label: 'read article',
          url: post.meta.path,
        },
        date: post.date || post.first_published_at,
      })),
    meta: childrenPages.meta || {},
    arrangeByField: {
      type: 'select',
      name: 'order',
      fieldSize: 'u-1/2 u-1/4@tablet u-width-15@xwide',
      options: [
        {
          value: '-date',
          label: 'Latest',
          selected: true,
        },
        {
          value: 'title',
          label: 'Title',
          selected: true,
        },
      ],
    },
    signUpForm: {
      title: 'Receive email alerts',
      copy: 'Get Barry Plant Media Hub news, blog articles and resources straight to your inbox',
      slug: signUpForm.slug,
      ...signUpForm,
    },
  }
}

BlogIndexPage.getRSSFeedItems = (_, { items }) => {
  return (
    (items &&
      items.length &&
      items.map((item) => ({
        title: item.title,
        id: `${domain}${item.link.url}`,
        link: `${domain}${item.link.url}`,
        description: item.preamble,
        date: new Date(Date.parse(item.date)),
        image: item.imgUrl,
      }))) ||
    []
  )
}

export default BlogIndexPage
