import React, { useEffect, useState } from 'react'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { getOffices } from '@lib/api-services/officesService'
import { UrlBuilder } from '@lib/helpers'
import OfficeSearch from '@components/Offices/OfficeSearchForm'
import { Container } from '@styles/global.styled'
import Breadcrumbs from '@global/Breadcrumbs'
import OfficeResults from '@components/Offices/OfficeResults'
import MobileNavbar from '@global/MobileNavbar'

const getBreadcrumbs = (slug, params) => {
  const breadcrumbs = [
    {
      label: 'Find an Office',
      url: `/${slug}/`,
    },
  ]

  if (params.search) {
    breadcrumbs.push({
      label: params.search,
      url: UrlBuilder(`/${slug}/`, { keyword: params.search }),
    })
  }

  return [
    ...breadcrumbs,
    {
      label: 'Search Results',
    },
  ]
}

const OfficeIndexPage = (props) => {
  const { pageData, slug, title } = props
  const [isLoading, setIsLoading] = useState(false)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    search: '',
    view: props.queryParams.view ?? 'grid',
  })
  const [offices, setOffices] = useState({ ...props.offices })

  const getOfficeList = async ({ search }) => {
    setQueryParams({ page: 1, search })
    try {
      const offices = await getOffices({ page: 1, search })
      setIsLoading(true)
      setOffices({ ...offices })
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setOffices({ ...props.offices })
  }, [props.offices])

  useEffect(() => {
    setQueryParams({
      page: 1,
      search: '',
      view: props.queryParams.view,
    })
  }, [props.queryParams.view])

  return (
    <>
      <Container>
        <Breadcrumbs breadcrumbs={getBreadcrumbs(slug, queryParams)} />
        <OfficeSearch
          title={title}
          onSubmit={getOfficeList}
          initialValues={{ keyword: queryParams.search ?? '' }}
        />
        <OfficeResults
          offices={offices}
          getOffices={getOffices}
          setOffices={setOffices}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
        <WagtailBlocksSection blocks={pageData.content_blocks} />
      </Container>
      <MobileNavbar currentPath={'offices'} />
    </>
  )
}

OfficeIndexPage.getInitialProps = async (ctx, pageData) => {
  const view = ctx.query.view ?? 'grid'

  const queryParams = {
    view: ctx.query.view ?? 'grid',
    per_page: 15,
    page: 1,
  }

  if (view === 'map') {
    queryParams.per_page = 500
  }

  const offices = await getOffices(queryParams)

  if (view === 'map') {
    offices.results = offices.results
      .filter((office) => office.longitude && office.latitude)
      .map((office) => ({
        ...office,
        location: {
          coordinates: [office.longitude, office.latitude],
        },
      }))
  }

  return { queryParams, offices, title: pageData.title }
}

export default OfficeIndexPage
