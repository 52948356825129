import Hero from '@global/Blocks/Hero'
import { getChildPages } from '@lib/api-services/cmsService'
import { apiFieldsQuery } from '@lib/variables'
import Breadcrumbs from '@global/Breadcrumbs'
import { Container, Section } from '@styles/global.styled'
import ArticleCard from '@global/ArticleCard'
import GridItems from '@global/GridItems'
import React from 'react'
import AwardDetails from '@components/AwardDetails'
import Divider from '@global/Blocks/Divider'

const formatPost = (data) => ({
  imgUrl: data.hero_image_thumb?.url,
  offices: data.offices?.map((office) => office.office.title),
  agents: data.agents?.map((agent) => agent.agent),
  link: { url: data.meta.path },
  linkLabel: 'read more',
  title: data.title,
  description: data.stripped_content,
})

const AwardPage = ({
  title,
  date,
  content,
  offices,
  agents,
  shareTitle,
  socialLinks,
  breadcrumbs,
  relatedAwards,
  pageData: { hero_image, show_related_awards, hero_image_anchor },
}) => {
  return (
    <React.Fragment>
      <Container>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {hero_image?.url && (
          <Hero
            title={null}
            bgImageUrl={hero_image.url}
            titlePosition='bottom'
            maxHeight='560px'
            noGradientBg
            bgPosition={hero_image_anchor}
          />
        )}
      </Container>


      <Section>
      <AwardDetails
        title={title}
        date={date}
        content={content}
        agents={agents}
        offices={offices}
        shareTitle={shareTitle}
        socialLinks={socialLinks}
      />
      </Section>
      {show_related_awards ? (
        <Section withPaddingBottom>
          <GridItems
            title='Related awards'
            itemSize={4}
            items={relatedAwards}
            minVisibleDesktopItems={3}
            renderItem={(post) => (
              <ArticleCard
                article={post}
                hideFavourite
                style={{ marginBottom: 0 }}
              />
            )}
          />
        </Section>
      ) : (
        <Divider />
      )}
    </React.Fragment>
  )
}

AwardPage.getInitialProps = async (ctx, pageData) => {
  let officeId =
    pageData.offices && pageData.offices[0] && pageData.offices[0].office
      ? pageData.offices[0].office.id
      : null

  // Related awards are:
  // 1. Under the same parent page as the award being displayed
  // 2. If the award has offices - belongs to the first office
  let childrenPages = null
  if (pageData.show_related_awards) {
    childrenPages = await getChildPages(
      'awards.AwardPage',
      pageData.meta.parent.id,
      4,
      1,
      { offices__office: officeId },
      apiFieldsQuery.AwardPage
    )
  }

  const relatedAwards = childrenPages?.items
    .filter((it) => it.id !== pageData.id)
    ?.map((post) => formatPost(post))

  console.log('pageData', pageData)
  return {
    title: pageData.title,
    show_related_awards: pageData.show_related_awards,
    content: pageData.content,
    agents: pageData.agents?.map((agent) => {
      return {
        id: agent.agent.id,
        image:
          agent.agent?.profile_image_thumbnail?.url ||
          agent.agent?.profile_image?.url,
        name: agent.agent.title,
        phone: agent.agent.phone,
        link: agent.agent.url,
      }
    }),
    date: pageData.date_of_award,
    offices: pageData.offices?.map((office) => {
      return {
        id: office.office.id,
        name: office.office.title,
        image: office.office.results_image_small,
        phone: office.office.phone,
        link: office.office.path,
      }
    }),
    gallery_items:
      pageData.gallery_items &&
      pageData.gallery_items.map((image) => {
        return {
          id: image.id,
          ...image.image_thumb,
        }
      }),
    relatedAwards,
    breadcrumbs: [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: pageData.meta?.parent.title,
        url: pageData.meta?.parent_path,
      },
      {
        label: pageData.title,
        url: pageData.meta?.path,
      },
    ],
  }
}

export default AwardPage
