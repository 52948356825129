import React, { useContext, useState } from 'react'
import { Col, Container, Row } from '@styles/global.styled'
import Image from '@global/Image'
import Button from '@global/Button'
import { Link } from '@routes'
import * as S from './Errors.styled'
import Modal from '@global/Modal'
import Field from '@global/FormInput/Field'
import { Formik } from 'formik'
import { UserContext } from '@context/UserContext'
import { detect } from 'detect-browser'
import { reportIssue } from '@lib/api-services/ErrorService'
const browser = detect()

const Errors = ({ status }) => {
  const errorData = {
    404: {
      title: (
        <>
          Anybody
          <S.BreakLine /> Home..?
        </>
      ),
      subtitle: "Sorry, looks like no one's here!",
      description:
        "Unfortunately, the page you're looking for couldn't be found.",
      image_url: '/imgs/404.png',
      button_actions: [
        {
          text: 'Properties for Sale',
          link: 'for-sale/properties-sale/',
          type: 'link',
          on_click: null,
        },
        {
          text: 'Properties for rent',
          link: 'rental-properties/properties-rent/',
          type: 'link',
          on_click: null,
        },
      ],
    },
    500: {
      title: 'Oops.',
      subtitle:
        'We apologise for the inconvenience - our website is currently unable to fulfil this request.',
      description: <>A text box for the user to fill in</>,
      image_url: '/imgs/500.png',
      button_actions: [
        {
          text: 'Report Issue',
          link: 'for-sale/properties-sale/',
          type: 'button',
          on_click: () => handleReport(),
        },
      ],
    },
    505: {
      title: 'Oops.',
      subtitle: 'It seems your web browser is not supported.',
      description: (
        <>
          We&apos;re constantly working to enhance our services, and this
          particular feature is not available at the moment. We appreciate your
          understanding!
          <br />
          To enjoy the best browsing experience and access all our
          website&apos;s exciting features, we recommend updating your web
          browser to the latest version.
        </>
      ),
      image_url: '/imgs/505.png',
      button_actions: [],
    },
  }

  const resultSuccess = () => {
    return (
      <S.MessageWrap>
        <S.Message>{msg}</S.Message>
        <Button type={'button'} color='primary' onClick={() => toggleModal()}>
          OK
        </Button>
      </S.MessageWrap>
    )
  }

  const [open, setOpen] = React.useState(false)
  const userContext = useContext(UserContext)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [msg, setMsg] = useState(
    `Thank you, an error report has been sent to our website developers.`
  )

  const error = errorData[status] || errorData[404]

  const handleReport = () => {
    setOpen(true)
  }

  const toggleModal = () => {
    setOpen((prev) => !prev)
    setIsSuccess(false)
  }

  const handleSubmit = async (values) => {
    const requestBody = {
      browser_type: `${browser.name} ${browser.version} ${browser.os}`,
      page: window.location.pathname,
      ...values,
    }
    requestBody['user_information'] =
      userContext.userProfile && userContext.userProfile.pk
        ? `${userContext.userProfile.first_name} ${userContext.userProfile.last_name} ${userContext.userProfile.email} ${userContext.userProfile.pk}`
        : 'User not logged in'
    try {
      await reportIssue(requestBody)
      setIsSuccess(true)
    } catch (error) {
      setMsg('Something went wrong!')
    }
  }

  return (
    <>
      {/*<Modal open={open} toggleModal={toggleModal} initialValues={error} />*/}

      <Modal
        className='timeable-modal'
        open={open}
        toggleModal={toggleModal}
        title={error.title}
      >
        <S.ModalBody>
          {isSuccess ? (
            resultSuccess()
          ) : (
            <Formik
              initialValues={{
                message: '',
              }}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                handleSubmit,
                getFieldProps,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <S.Form>
                    <Field
                      type='textarea'
                      label={
                        'Please let us know if you have any comments (optional):'
                      }
                      {...getFieldProps('message')}
                    />
                    <Button
                      type={'submit'}
                      color='primary'
                      disabled={isSubmitting}
                    >
                      Report Issues
                    </Button>
                  </S.Form>
                </form>
              )}
            </Formik>
          )}
        </S.ModalBody>
      </Modal>

      <S.ErrorsContainer>
        <Container style={{ width: '100%' }}>
          <Row gutter={10}>
            <Col md={5} flex>
              <S.ErrorLeftContainer>
                <S.ErrorTitle>{error.title}</S.ErrorTitle>
                <S.ErrorSubTitle>{error.subtitle}</S.ErrorSubTitle>
                <S.ErrorDescription>{error.description}</S.ErrorDescription>
                <S.ErrorButtonContainer>
                  {error.button_actions.map((action, index) =>
                    action.type === 'button' ? (
                      <Button
                        key={index}
                        color='secondary'
                        onClick={action.on_click}
                      >
                        {action.text}
                      </Button>
                    ) : (
                      <Link key={index} href={action.link}>
                        <Button color='secondary'>{action.text}</Button>
                      </Link>
                    )
                  )}
                </S.ErrorButtonContainer>
              </S.ErrorLeftContainer>
            </Col>
            <Col md={7}>
              <S.ErrorRightContainer>
                <Image src={error.image_url} alt='' />
              </S.ErrorRightContainer>
            </Col>
          </Row>
        </Container>
      </S.ErrorsContainer>
    </>
  )
}

export default Errors
