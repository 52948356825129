import React from 'react'
import { getChildPages } from '@lib/api-services/cmsService'
import { apiFieldsQuery } from '@lib/variables'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import RegionList from '@components/SuburbProfiles/RegionList'
import SuburbProfilesHero from '@components/SuburbProfiles/SuburbProfilesHero'

const LocalInformationLanding = (props) => {
  const {
    region,
    pageData: { title, sub_heading, intro, content_blocks },
  } = props

  return (
    <>
      <SuburbProfilesHero
        isRegion
        title={title}
        subTitle={sub_heading}
        content={intro}
      />
      {region.blocks.length > 0 && (
        <RegionList title={region.title} blocks={region.blocks} />
      )}
      <WagtailBlocksSection blocks={content_blocks} />
    </>
  )
}

LocalInformationLanding.getInitialProps = async (ctx, pageData) => {
  let childrenPages = await getChildPages(
    'suburbs.RegionIndexPage',
    pageData.id,
    null,
    null,
    null,
    apiFieldsQuery.RegionIndexPage
  )

  return {
    region: {
      title: 'Explore Suburbs by Region',
      blocks: childrenPages.items
        ? childrenPages.items.map((item) => {
            return {
              content: item.hover_text,
              title: item.title,
              image: item?.hero_image?.url,
              link: item.meta.path,
            }
          })
        : [],
    },
  }
}

export default LocalInformationLanding
