import WagtailBlocksSection from '../components/global/Blocks/WagtailBlocksSection'
import HeroSection from '../components/global/Blocks/Hero'
import BasicText from '../components/global/Blocks/FullWidthText'
import { getChildPages } from '../lib/api-services/cmsService'
import { apiFieldsQuery } from '../lib/variables'
import { Section } from '@styles/global.styled'
import TwinEqualCTA from '../components/global/Blocks/TwinEqualCTA'

const LifestyleCategoryPage = ({ suburbs, lifeStyles, pageData }) => {
  const { content } = pageData
  return (
    <>
      {pageData.hero_image && (
        <HeroSection
          title={pageData.title}
          bgImageUrl={pageData.hero_image.url}
          imagePosition={pageData.hero_image_anchor}
          titlePosition='bottom'
        />
      )}
      <Section>
        <BasicText link={null} title={null} para={content} />
      </Section>
      {suburbs.items.length > 0 && (
        <Section>
          <TwinEqualCTA
            title={suburbs.title}
            titleStyle='normal'
            items={suburbs.items}
          />
        </Section>
      )}
      {lifeStyles.items.length > 0 && (
        <Section withPaddingBottom={pageData.content_blocks?.length === 0}>
          <TwinEqualCTA titleStyle='normal' {...lifeStyles} />
        </Section>
      )}
      <WagtailBlocksSection blocks={pageData.content_blocks} />
    </>
  )
}
LifestyleCategoryPage.getInitialProps = async (ctx, pageData) => {
  const suburbs = await getChildPages(
    'suburbs.SuburbPage',
    null,
    500,
    null,
    { lifestyle_categories__category: pageData.id },
    apiFieldsQuery.SuburbPage
  )
  const lifeStyles = await getChildPages(
    'suburbs.LifestyleCategoryPage',
    null,
    10,
    null,
    null,
    apiFieldsQuery.LifestyleCategoryPage
  )

  return {
    suburbs: {
      title: 'Suburb profiles',
      items: suburbs.items
        ? suburbs.items.map((item) => {
            return {
              description: item.preamble,
              title: item.title,
              image: item.featured_image_thumb?.url,
              url: item.meta.path,
            }
          })
        : [],
    },
    lifeStyles: {
      title: 'Suburb profiles by lifestyle',
      moreLink: {
        label: 'view more',
        url: pageData && pageData.meta && pageData.meta.parent_path,
      },
      items: lifeStyles.items
        ? lifeStyles.items.map((item) => {
            return {
              description: item.content,
              title: item.title,
              image: item.hero_image_small?.url,
              url: item.meta.path,
            }
          })
        : [],
    },
  }
}
export default LifestyleCategoryPage
